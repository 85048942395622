export const PUBLIC_ROUTE = {
  ROOT: "/",
  LOGIN: "/login",
  LANDING: "/",
  PAGE_404: "/404",
  PAGE_500: "/500",
};

export const PRIVATE_ROUTE = {
  USER_ADMIN_MANAGE: "/user/admin",
};
