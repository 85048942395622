import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";

import ServiceWrapper from "../components/wrapper/ServiceWrapper";
import { accessTokenState } from "../recoil/auth";

interface IPrivateRoute extends RouteProps {
  component: React.ComponentType<any>;
  children?: React.ReactNode;
}

const PrivateRoute = ({
  component: Component,
  children,
  ...rest
}: IPrivateRoute) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        accessTokenState ? (
          <ServiceWrapper>{<Component {...props} />}</ServiceWrapper>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
